import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card pt-4 mb-xl-9" }
const _hoisted_4 = { class: "card-header border-0" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = { class: "fw-bolder" }
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = { class: "fw-bolder fs-2" }
const _hoisted_9 = { class: "fs-7 fw-normal text-muted" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "card bg-opacity-25 h-100 bg-info" }
const _hoisted_12 = { class: "card-header flex-nowrap border-0 pt-9" }
const _hoisted_13 = { class: "card-title m-0" }
const _hoisted_14 = {
  href: "#",
  class: "fs-4 fw-bold text-hover-primary text-gray-900 m-0"
}
const _hoisted_15 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_16 = { class: "d-flex align-items-center fw-bold" }
const _hoisted_17 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t("pages.usefulTraining.title")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("pages.usefulTraining.desc")), 1)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.usefultrainings, (usefultraining) => {
        return (_openBlock(), _createElementBlock("div", {
          key: usefultraining.pk,
          class: "col-4 mb-4"
        }, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-chalkboard-teacher fs-3 me-3 text-gray-900" }, null, -1)),
                _createElementVNode("a", _hoisted_14, _toDisplayString(usefultraining.label), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("a", {
                  href: usefultraining.link,
                  target: "_blank",
                  class: "btn btn-info btn-sm btn-light btn-active-light-info"
                }, "Accéder à la formation ", 8, _hoisted_17)
              ])
            ])
          ])
        ]))
      }), 128))
    ])
  ], 64))
}